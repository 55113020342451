import * as React from "react";
import UploadIcon from "@mui/icons-material/Upload";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
} from "@mui/joy";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import {
  registerUserAsync,
  setUserData,
  userState,
} from "../../../reducers/userSlice";
const allowedExtensions = ["csv"];
export default function CSVForm() {
  const dispatch = useDispatch();
  const { loading } = useSelector(userState);
  const [error, setError] = React.useState("");

  const [file, setFile] = React.useState(null);
  const handleFileChange = (e) => {
    setError("");

    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];

      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      }
      setFile(inputFile);
    }
  };
  const handleParse = () => {
    if (!file) return setError("Enter a valid file");

    if (!(file instanceof Blob)) {
      setError("Invalid file type");
      return;
    }

    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      let parsedData = csv?.data;
      parsedData = parsedData.filter((row) =>
        Object.values(row).some((value) => value !== "")
      );
      dispatch(setUserData({ value: parsedData }));
      dispatch(registerUserAsync({ parsedData }));
    };

    reader.readAsText(file);
  };
  return (
    <Grid container spacing={2} sx={{ flexGrow: 1 }} component="form">
      <Grid xs={12} md={6}>
        <FormControl id="Id" required size="sm" color="primary">
          <FormLabel>Choose csv file</FormLabel>
          <Input
            placeholder="choose file"
            id="csvInput"
            name="file"
            type="File"
            autoComplete="on"
            autoFocus
            fullWidth
            onChange={handleFileChange}
            variant="outlined"
            accept=".pdf"
          />
          {error ? <FormHelperText>{error}</FormHelperText> : null}
        </FormControl>
      </Grid>
      <Grid
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "end",
          gap: 2,
        }}
      >
        <Button
          size="sm"
          sx={{ px: 2 }}
          startDecorator={<UploadIcon />}
          onClick={handleParse}
          loading={loading}
        >
          Upload
        </Button>
      </Grid>
    </Grid>
  );
}
