import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Checkbox from "@mui/joy/Checkbox";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
// icons
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/joy";
import { getLogAsync, logState } from "../../reducers/logSlice";
import moment from "moment/moment";
import { CSVLink } from "react-csv";

export default function OrderTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);
  const [logReport, setLogReport] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery, setSearchQuery] = React.useState("");

  const { userData } = useSelector(logState);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = userData?.map((n, i) => i);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(parseInt(newValue.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = () => {
    if (userData?.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? userData?.length
      : Math.min(userData?.length, (page + 1) * rowsPerPage);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData.length) : 0;

  const isSelected = (name) => selected.indexOf(name) !== -1;
  React.useEffect(() => {
    dispatch(getLogAsync({}));
  }, [dispatch, navigate]);

  const handleCSV = () => {
    let array = [];
    for (let log of userData) {
      let timestamp = log.timestamp;
      const stringTime = moment(
        timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
      ).format("YYYY-MM-DD HH:mm:ss");
      array.push({
        Email: log.email,
        "Date/Time": stringTime,
      });
    }
    setLogReport(array)
  };
  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for order</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
            onChange={handleInputChange}
          />
        </FormControl>
        {selected.length > 0 ? (
          <Tooltip
            title="Delete"
            sx={{
              height: "30px",
              mt: "auto",
            }}
          >
            <IconButton size="sm" color="danger" variant="solid">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        <CSVLink data={logReport} onClick={handleCSV}>Download me</CSVLink>
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "none", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 20, textAlign: "left", padding: "12px 6px" }}>
                <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== userData?.length
                  }
                  checked={selected.length === userData?.length}
                  onChange={handleSelectAllClick}
                  color={
                    selected.length > 0 || selected.length === userData?.length
                      ? "primary"
                      : undefined
                  }
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </th>
              <th style={{ width: 120, padding: "12px 6px" }}>Email ID</th>
              <th style={{ width: 140, padding: "12px 6px" }}>Date/Time </th>
            </tr>
          </thead>
          <tbody>
            {userData
              ? userData
                  ?.filter((row) =>
                    row.email.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, i) => {
                    const isItemSelected = isSelected(i);
                    const labelId = `enhanced-table-checkbox-${i}`;
                    let timestamp = row.timestamp;
                    const stringTime = moment(
                      timestamp._seconds * 1000 +
                        timestamp._nanoseconds / 1000000
                    ).format("YYYY-MM-DD HH:mm:ss");
                    return (
                      <tr key={i}>
                        <td style={{ textAlign: "left", width: 20 }}>
                          <Checkbox
                            size="sm"
                            checked={selected.includes(i)}
                            color={selected.includes(i) ? "primary" : undefined}
                            onChange={(event) => {
                              setSelected((ids) =>
                                event.target.checked
                                  ? ids.concat(i)
                                  : ids.filter((itemId) => itemId !== i)
                              );
                            }}
                            slotProps={{
                              checkbox: { sx: { textAlign: "left" } },
                            }}
                            sx={{ verticalAlign: "text-bottom" }}
                          />
                        </td>
                        <td>
                          <Typography level="body-xs">{row.email}</Typography>
                        </td>
                        <td>
                          <Typography level="body-xs">{stringTime}</Typography>
                        </td>
                      </tr>
                    );
                  })
              : null}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
          justifyContent: "space-between",
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          disabled={page === 0}
          onClick={() => handleChangePage(page - 1)}
        >
          Previous
        </Button>
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          disabled={
            userData?.length !== -1
              ? page >= Math.ceil(userData?.length / rowsPerPage) - 1
              : false
          }
          onClick={() => handleChangePage(page + 1)}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}
