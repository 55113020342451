/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import GlobalStyles from "@mui/joy/GlobalStyles";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";

import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import ColorSchemeToggle from "./ColorSchemeToggle";
import { closeSidebar } from "../../utils/utils";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItems from "../dashboard/ListItem";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authState } from "../../reducers/authSlice";

export default function Sidebar() {
  const navigate = useNavigate();
  const {isLogin} = useSelector(authState)
  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: {
          xs: "fixed",
          md: "sticky",
        },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          md: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 10000,
        height: "100dvh",
        width: "var(--Sidebar-width)",
        top: 0,
        p: 1.5,
        py: 3,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "224px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "256px",
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          bgcolor: "background.body",
          opacity: "calc(var(--SideNavigation-slideIn, 0) - 0.2)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography fontWeight="xl">Dashboard</Typography>
        <ColorSchemeToggle sx={{ ml: "auto" }} />
      </Box>
      {/* <Input
        startDecorator={<i data-feather="search" />}
        placeholder="Search"
      /> */}
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ListItems />
      </Box>
      <Divider />
      <Box sx={{ display: isLogin?"flex":"none", gap: 1, alignItems: "center" }}>
        <Avatar variant="outlined" src="/static/images/avatar/3.jpg" />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography fontSize="sm" fontWeight="lg">
            Dashboard Admin
          </Typography>
          <Typography level="body3">admin@gmail.com</Typography>
        </Box>
        <IconButton
          variant="plain"
          color="neutral"
          onClick={() => {
            window.localStorage.clear();
            window.sessionStorage.clear();
            navigate("/");
            window.location.reload();
          }}
        >
          <LogoutIcon />
        </IconButton>
      </Box>
    </Sheet>
  );
}
