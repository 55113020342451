import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/config";
const initialState = {
  loading:true,
  dashboardTab: "CSV Upload",
  sosCollection: "",
};


export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardTab: (state, action) => {
      state.dashboardTab = action.payload.tab;
    },
  },
});

export const { setDashboardTab } = dashboardSlice.actions;
export const dashboardState = (state) => state.dashboard;
export default dashboardSlice.reducer;
