import * as React from "react";

import { Container, Typography } from "@mui/joy";

import CSVForm from "./Form/CSVForm";

const App = () => {
  return (
    <Container>
      <Typography level="h5" mb={3}>
        Create User
      </Typography>
      <CSVForm />
    </Container>
  );
};

export default App;
