import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { functions } from "../firebase/config";
import { httpsCallable } from "firebase/functions";
const initialState = {
  currentTab: "Upload CSV",
  userData: "",
  password: "password",
  claim: "afrisummit",
  loading: false,
};

export const getUserAsync = createAsyncThunk(
  "user/getUserUser",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { claim } = getState().user;
      const accessLevel = claim;
      const getDoc = httpsCallable(functions, "getUser");
      const response = await getDoc({ accessLevel });
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        alert(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);
export const registerSingleUserAsync = createAsyncThunk(
  "user/registerSingleUser",
  async ({ email }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { password, claim } = getState().user;
      const addDoc = httpsCallable(functions, "createUser");
      const response = await addDoc({ email, password, claim });
      if (response.data.message === "User created successfully") {
        alert("User created successfully");
      }
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        alert(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const registerUserAsync = createAsyncThunk(
  "user/registerUser",
  async ({ parsedData }, { dispatch, getState, rejectWithValue }) => {
    try {
      const responses = [];
      const { password, claim } = getState().user;
      const addDoc = httpsCallable(functions, "createUser");
      for (const user of parsedData) {
        try {
          let email = user.Email;
          const response = await addDoc({ email, password, claim });
          responses.push(response.user);
        } catch (error) {
          if (error.code === "auth/email-already-in-use") {
            alert(`Skipped duplicate email: ${user.Email}`);
          } else {
            throw error; // Re-throw other errors for proper rejection
          }
        }
      }
      if (responses.length != 0) {
        alert(`${responses.length} Users created successfully`);
      }
      return responses;
    } catch (error) {
      if (error.name === "FirebaseError") {
        alert(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteUserAsync = createAsyncThunk(
  "user/deleteUser",
  async ({ parsedData }, { dispatch, getState, rejectWithValue }) => {
    try {
      const responses = [];
      const { claim } = getState().user;
      const addDoc = httpsCallable(functions, "deleteUser");
      for (const user of parsedData) {
        try {
          let email = user;
          const response = await addDoc({ email, claim });
          responses.push(response.user);
        } catch (error) {
          if (error.code === "auth/email-already-in-use") {
            alert(`Skipped duplicate email: ${user.Email}`);
          } else {
            throw error; // Re-throw other errors for proper rejection
          }
        }
      }
      if (responses.length != 0) {
        alert(`${responses.length} Users delete successfully`);
      }
      return responses;
    } catch (error) {
      if (error.name === "FirebaseError") {
        alert(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload.value;
    },
    setUserData: (state, action) => {
      state.userData = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload.data;
      })
      .addCase(getUserAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(registerSingleUserAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(registerSingleUserAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(registerSingleUserAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(registerUserAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(registerUserAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(registerUserAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setCurrentTab, setUserData } = userSlice.actions;
export const userState = (state) => state.user;
export default userSlice.reducer;
