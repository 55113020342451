import React from "react";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Person2Icon from "@mui/icons-material/Person2";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SummarizeIcon from '@mui/icons-material/Summarize';
import GroupIcon from '@mui/icons-material/Group';
import { useDispatch, useSelector } from "react-redux";
import { dashboardState, setDashboardTab } from "../../reducers/tabSlice";

const data = [
  {
    name: "CSV Upload",
    icon: <GroupIcon />,
  },
  {
    name: "Single User",
    icon: <Person2Icon />,
  },
  {
    name: "User List",
    icon: <ManageAccountsIcon />,
  },
  {
    name: "Log Report",
    icon: <SummarizeIcon />,
  },

];

export default function ListItems() {
  const dispatch = useDispatch();
  const { dashboardTab } = useSelector(dashboardState);
  return (
    <>
      <List
        sx={{
          "--ListItem-radius": "8px",
          "--List-gap": "4px",
          "--List-nestedInsetStart": "40px",
        }}
      >
        {data?.map((item, i) => (
          <ListItem
            key={i}
            onClick={() => dispatch(setDashboardTab({ tab: item.name }))}
          >
            <ListItemButton>
              <ListItemDecorator
                sx={{
                  color: dashboardTab === item.name ? "#007FFF" : "inherit",
                }}
              >
                {item.icon}
              </ListItemDecorator>
              <ListItemContent
                sx={{
                  color: dashboardTab === item.name ? "#007FFF" : "inherit",
                }}
              >
                {item.name}
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
}
