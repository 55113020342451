import * as React from "react";

import { Container, Typography } from "@mui/joy";

import UserForm from "./Form/UserForm";

const App = () => {
  return (
    <Container>
      <Typography level="h5" mb={3}>
        Create User
      </Typography>
      <UserForm />
    </Container>
  );
};

export default App;
