import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { functions } from "../firebase/config";
import { httpsCallable } from "firebase/functions";
const initialState = {
  userData: "",
  password: "password",
  claim: "afrisummit",
  loading:false
};

export const getLogAsync = createAsyncThunk(
  "log/getLogs",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { claim } = getState().user;
      const accessLevel = claim;
      const getDoc = httpsCallable(functions, "getLog");
      const response = await getDoc({accessLevel});
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        alert(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const logSlice = createSlice({
  name: "log",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload.value;
    },
  }, extraReducers: (builder) => {
    builder
      .addCase(getLogAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getLogAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload.data;
      })
      .addCase(getLogAsync.rejected, (state, action) => {
        state.loading = false;
      })
    
  },
});

export const {  setUserData } = logSlice.actions;
export const logState = (state) => state.log;
export default logSlice.reducer;
