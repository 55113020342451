import { Button, FormControl, FormLabel, Grid, Input } from "@mui/joy";
import React from "react";
import { useForm } from "react-hook-form";
import { registerSingleUserAsync, userState } from "../../../reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";

export default function UserForm() {
  const { register, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  const {loading} = useSelector(userState)
  const uploadUser = (data) => {
    const email = data.email;
    dispatch(registerSingleUserAsync({ email }));
    reset();
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{ flexGrow: 1 }}
      component="form"
      onSubmit={handleSubmit(uploadUser)}
    >
      <Grid xs={12} md={6}>
        <FormControl id="Id" required size="sm" color="primary">
          <FormLabel>Full Name</FormLabel>
          <Input
            name="name"
            type="text"
            fullWidth
            variant="outlined"
            {...register("name")}
          />
        </FormControl>
      </Grid>
      <Grid xs={12} md={6}>
        <FormControl id="Id" required size="sm" color="primary">
          <FormLabel>User Email</FormLabel>
          <Input
            name="Email"
            type="email"
            fullWidth
            variant="outlined"
            {...register("email")}
          />
        </FormControl>
      </Grid>
      <Grid
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "end",
          gap: 2,
        }}
      >
        <Button size="sm" sx={{ px: 2 }} type="submit" loading={loading}>
          Add
        </Button>
      </Grid>
    </Grid>
  );
}
