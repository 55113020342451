import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "../reducers/tabSlice";
import userReducer from "../reducers/userSlice";
import authReducer from "../reducers/authSlice";
import logReducer from "../reducers/logSlice";

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    user: userReducer,
    auth: authReducer,
    log: logReducer,
  },
});
