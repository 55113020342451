import * as React from "react";
import { ColorPaletteProp } from "@mui/joy/styles";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Checkbox from "@mui/joy/Checkbox";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";
// icons
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import {
  deleteUserAsync,
  getUserAsync,
  userState,
} from "../../reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/joy";

export default function OrderTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteUser, setDeleteUser] = React.useState("");
  const [searchQuery, setSearchQuery] = React.useState("");

  const { userData } = useSelector(userState);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = userData?.map((n, i) => i);
      setSelected(newSelected);
      console.log(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleDelete = () => {
    let parsedData = [];
    for (let log of userData) {
      parsedData.push(log.email);
    }
    dispatch(deleteUserAsync({ parsedData }));
    setDeleteUser(parsedData);
  };
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(parseInt(newValue.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = () => {
    if (userData?.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? userData?.length
      : Math.min(userData?.length, (page + 1) * rowsPerPage);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData.length) : 0;

  const isSelected = (name) => selected.indexOf(name) !== -1;
  React.useEffect(() => {
    dispatch(getUserAsync({}));
  }, [dispatch, navigate]);
  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for order</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
            onChange={handleInputChange}
          />
        </FormControl>
        {selected.length > 0 ? (
          <Tooltip
            title="Delete"
            sx={{
              height: "30px",
              mt: "auto",
            }}
          >
            <IconButton
              size="sm"
              color="danger"
              variant="solid"
              onClick={handleDelete}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "none", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 20, textAlign: "left", padding: "12px 6px" }}>
                <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== userData?.length
                  }
                  checked={selected.length === userData?.length}
                  onChange={handleSelectAllClick}
                  color={
                    selected.length > 0 || selected.length === userData?.length
                      ? "primary"
                      : undefined
                  }
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </th>
              <th style={{ width: 120, padding: "12px 6px" }}>Email ID</th>
              <th style={{ width: 140, padding: "12px 6px" }}>Event </th>
            </tr>
          </thead>
          <tbody>
            {userData
              ? userData
                  ?.filter((row) =>
                    row.email.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, i) => {
                    const isItemSelected = isSelected(i);
                    const labelId = `enhanced-table-checkbox-${i}`;
                    return (
                      <tr key={i}>
                        <td style={{ textAlign: "left", width: 20 }}>
                          <Checkbox
                            size="sm"
                            checked={selected.includes(i)}
                            color={selected.includes(i) ? "primary" : undefined}
                            onChange={(event) => {
                              setSelected((ids) =>
                                event.target.checked
                                  ? ids.concat(i)
                                  : ids.filter((itemId) => itemId !== i)
                              );
                            }}
                            slotProps={{
                              checkbox: { sx: { textAlign: "left" } },
                            }}
                            sx={{ verticalAlign: "text-bottom" }}
                          />
                        </td>
                        <td>
                          <Typography level="body-xs">{row.email}</Typography>
                        </td>
                        <td>
                          <Typography level="body-xs">{row.claim}</Typography>
                        </td>
                      </tr>
                    );
                  })
              : null}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
          justifyContent: "space-between",
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          disabled={page === 0}
          onClick={() => handleChangePage(page - 1)}
        >
          Previous
        </Button>
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          disabled={
            userData?.length !== -1
              ? page >= Math.ceil(userData?.length / rowsPerPage) - 1
              : false
          }
          onClick={() => handleChangePage(page + 1)}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}
